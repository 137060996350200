import { render, staticRenderFns } from "./modelTraining.vue?vue&type=template&id=6cb8cc75&scoped=true&"
import script from "./modelTraining.vue?vue&type=script&lang=js&"
export * from "./modelTraining.vue?vue&type=script&lang=js&"
import style0 from "./modelTraining.vue?vue&type=style&index=0&id=6cb8cc75&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb8cc75",
  null
  
)

export default component.exports